.schedule-invite-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  input {
    width: 100%;
    border: none;
    border-bottom: 2px solid rgb(188, 185, 185);
    background-color: #f5f5f5;
    border-radius: 6px 6px 0 0;
    padding: 10px 0;
    margin-bottom: 3px;
    font-size: 16px;
  }

  label {
    color: rgb(143, 142, 142);
    font-size: 11px;
    text-transform: capitalize;
    font-size: 16px;
  }
}

.warning-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(80, 80, 76, 0.8);
  z-index: 3;
  display: none;

  .warning-content {
    width: 270px;
    height: 250px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;

    .warning-text {
      h3 {
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .warning-button {
      button {
        background-color: #fff;
        color: #303f9f;
        padding: 10px 15px;
        border-radius: 6px;
        border: 1px solid #303f9f;
        cursor: pointer;
      }
    }
  }
}

.warning-container.modal-warning {
  display: flex;
}

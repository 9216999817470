.date-and-time {
  width: 100%;
  border-bottom: 2px solid rgb(187, 223, 67);
  border-top: 2px solid rgb(187, 223, 67);
  text-align: center;
  margin: 8px 0;
  padding: 12px 0;

  p {
    margin-top: 12px;
  }
}

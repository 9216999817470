.custom-select {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  color: #000;
  background-color: white;
  .selected-option {
    border: none;
    border-bottom: 2px solid rgb(188, 185, 185);
    background-color: #f5f5f5;
    border-radius: 6px 6px 0 0;
    padding: 10px 0;
    margin-bottom: 3px;
    font-size: 16px;
    cursor: pointer;
  }

  .custom-select-label {
    color: rgb(143, 142, 142);
    font-size: 11px;
    text-transform: capitalize;
    font-size: 16px;
  }
}

.select-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  margin-top: 1px;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  display: block;
  z-index: 1;
}
.select-options li {
  padding: 5px;
  cursor: pointer;
}
.select-options li:hover {
  background-color: #ddd;
}

.custom-select.disabled {
  opacity: 0.6;
  pointer-events: none;
}

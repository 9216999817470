.groups-container {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;
  ul {
    width: 100%;
    list-style: none;
  }

  li {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .groups-single-item {
      width: 98%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 2px;
    }

    .groups-item-member-icon {
      margin-right: 4px;

      .schedule-group-single-icons {
        width: 40px;
        height: 40px;
      }
    }

    .groups-item-content {
      width: 95%;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #dcf4ff;
      padding: 0 5px;
      border-radius: 6px;
      .groups-item-name {
        flex: 1;
        text-align: left;
        font-weight: bold;

        span {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 15px;
        }
      }

      .groupe-item-icons {
        width: 90px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .groups-item-members {
          text-align: right;
          font-weight: bold;
          margin-right: 16px;
        }

        .groups-item-menu {
          img {
            display: block;
          }
        }
      }
    }
  }
}

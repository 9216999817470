.provider-registraton-category {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-bottom: 50px;

  .provider-registraton-header {
    margin: 30px;
    text-transform: capitalize;
    color: var(--positive_bg_color);
  }

  .provider-registraton-subtext {
    text-align: center;
    margin-bottom: 10px;
  }

  .provider-registraton-categories {
    width: 100%;
    ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 170px;
        height: 150px;
        margin: 5px;
        list-style-type: none;
        background-color: lightyellow;
        border-radius: 6px;
        border: 2px solid var(--positive_bg_color);
        text-transform: capitalize;
        cursor: pointer;

        .caregory-name {
          text-align: center;
        }

        img {
          width: 65px;
        }
      }
    }
  }

  .provider-registraton-back {
    margin: 20px;
    font-weight: 700;
    color: #8f8f8f;
    cursor: pointer;
  }
}

.provider-registraton-adderss {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-bottom: 50px;

  .provider-registraton-header {
    margin: 30px;
    text-transform: capitalize;
    color: var(--positive_bg_color);
  }

  .provider-registraton-subtext {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .provider-address-form {
    form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .adddress-validation {
    color: red;
    text-align: center;
    width: 340px;
    height: 30px;
    margin-top: 5px;
  }

  .provider-registraton-back {
    margin: 20px;
    font-weight: 700;
    color: #8f8f8f;
    cursor: pointer;
  }
}

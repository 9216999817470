.button {
  width: 340px;
  padding: 10px 0;
  margin: 0 5px;
  margin-top: 10px;
  border: none;
  border-radius: 6px;
  background-color: var(--positive_bg_color);
  text-transform: capitalize;
  color: white;
  cursor: pointer;
}

@media (max-width: 340px) {
  .button {
    width: 80%;
  }
}

.role-selector-container {
  .role-title {
    display: flex;
    justify-content: center;
    margin: 30px;

    h1 {
      color: var(--positive_bg_color);
      text-transform: capitalize;
    }
  }

  .role-selector-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    .role-subtitle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 10px;
      h3 {
        text-align: center;
        font-size: 1rem;
      }
    }

    .user-section {
      width: 98%;
      margin-bottom: 10px;
      .user {
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 10px;

        border-radius: 6px;
        border: 2px solid var(--positive_bg_color);
        background-color: lightyellow;
        cursor: pointer;

        .role-text {
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }

  .role-to-login {
    font-weight: 700;
    color: #8f8f8f;
    cursor: pointer;
  }
}

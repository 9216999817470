.invitation-container {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 55px;

  .invitation-container-ul {
    width: 97%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
}

.invitation-days {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px;

  div {
    width: 10%;
    height: 50px;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    background-color: #ffffff;
    border: 1px solid rgb(187, 223, 67);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &:last-child {
      margin-right: 0;
    }

    span {
      &:first-child {
        font-size: 18px;
        text-transform: capitalize;
      }
      &:last-child {
        font-size: 13px;
      }
    }
  }
  .active {
    background-color: var(--positive_bg_color);
    color: #ffffff;
  }
}

.empty-invitation {
  text-align: center;
  h2 {
    color: var(--negative_bg_color);
  }
}

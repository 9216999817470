.install-modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 9999;
}

.install-modal {
  position: absolute;
  top: -200%;
  width: 85%;
  height: 88%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #60605c;
  border-radius: 6px;
  padding: 0 15px;
  animation: modal-popup 1s forwards;
}

.install-modal button {
  color: #fff;
}

.install-modal-text {
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
}

.install-modal-text h1 {
  margin-bottom: 25px;
}

.install-ios-steps {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #fff;
  font-weight: 600;
  margin-bottom: 25px;
  margin-left: 30px;
}

.install-modal button {
  width: 200px;
  padding: 15px 0;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  background-color: var(--positive_bg_color);
  cursor: pointer;
  margin-bottom: 20px;
}

.install-modal button::first-letter {
  text-transform: uppercase;
}

.no-ask {
  display: flex;
  align-items: center;
  color: #fff;
}

.no-ask input {
  margin-right: 5px;
}

.no-ask label::first-letter {
  text-transform: uppercase;
}

.install-modal-container.disabled {
  display: none;
}

@keyframes modal-popup {
  100% {
    position: absolute;
    top: 60px;
  }
}

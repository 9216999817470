.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(80, 80, 76, 0.8);
  z-index: 3;
  display: none;

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;

    .modal-text {
      text-transform: capitalize;
      margin-bottom: 15px;
    }
    .modal-sub-text {
      color: #52575c;
      margin-bottom: 15px;
    }

    .input-modal {
      width: 100%;
    }
    .modal-buttons {
      width: 100%;
      display: flex;
      justify-content: space-around;
      div {
        width: 50%;

        &:first-child {
          margin-right: 5px;
        }
        button {
          width: 100%;
          color: #fff;
          border: none;
          border-radius: 6px;
          padding: 10px;
          text-transform: capitalize;
          cursor: pointer;
        }
      }
      #modal-confirm {
        background-color: var(--positive_bg_color);
      }

      #modal-censel {
        background-color: var(--negative_bg_color);
      }
    }
  }
}

.modal-container.modal-confirm {
  display: flex;
}

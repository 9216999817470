.registration {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.registration-form {
  width: 100%;
  height: 80svh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.sign-up {
  margin-top: 30px;
  color: var(--positive_bg_color);
  text-transform: capitalize;
}
.registration-form h3 {
  color: red;
  text-align: center;
  width: 340px;
  height: 30px;
  margin-top: 10px;
}

.registration-form h3::first-letter {
  text-transform: uppercase;
}

.successfully-registered {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(80, 80, 76, 0.8);
  z-index: 3;
  h1 {
    color: var(--positive_bg_color);
  }
}

@media (max-width: 340px) {
  .registration-form h3 {
    width: 270px;
  }
}

.profile-container {
  width: 100%;
  margin-top: 60px;

  .profile-container-info {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;

    .profile-contacts {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .profile-container-name {
        margin-top: 30px;
        text-transform: capitalize;
      }

      div {
        margin-bottom: 20px;
        &:first-child {
          width: 80%;
          text-align: center;

          border: none;
          border-bottom: 2px solid rgb(188, 185, 185);
          background-color: #f5f5f5;
          border-radius: 6px 6px 0 0;
          padding: 10px 0;
          margin-bottom: 6px;
        }
        &:last-child {
          text-transform: capitalize;
        }
      }

      margin: 10px;
    }
  }

  .profile-button {
    position: fixed;
    bottom: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      width: 90%;
      padding: 10px 0;
      border-radius: 5px;
      border: none;
      background-color: rgb(187, 223, 67);
      color: #fff;
      text-transform: capitalize;
      font-weight: bold;
      cursor: pointer;
      color: var(--postitiv_collar);
    }
  }
}

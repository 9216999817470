.consumer-container {
  width: 100%;
  margin-top: 60px;
  padding-bottom: 110px;

  .schedule-list-container {
    .schedule-day {
      display: flex;
      justify-content: center;
      padding: 4px 0 4px 16px;
      font-weight: 400;
      color: black;
      background: none;

      > span {
        border-bottom: 1px solid rgb(187, 223, 67);
        border-top: 1px solid rgb(187, 223, 67);
        width: 90%;
        text-align: center;
      }
    }

    ul {
      display: flex;
      flex-direction: column;

      li {
        width: 100%;

        .sub-single-schedule-container {
          margin-top: 2px;
          &:not(:first-child) {
            .sub-schedule-day {
              opacity: 0;
            }
          }

          display: flex;
          justify-content: space-around;
          align-items: center;

          .single-schedule-description {
            position: absolute;
            left: 30%;
            width: 215px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            text-align: center;

            &::first-letter {
              text-transform: uppercase;
            }
          }
          .sub-schedule-day-container {
            position: relative;
            margin: 0 2px;
            .sub-schedule-day {
              width: 20px;
              text-align: center;
              padding: 10px;
              border-radius: 50%;
              background-color: #d9d9d9;
            }
            .schedule-line {
              position: absolute;
              top: 10px;
              right: 50%;
              height: 70px;
              width: 2px;
              background-color: black;
              z-index: -1;
            }
          }

          .single-schedule {
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #dcf4ff;
            padding: 5px 5px;
            margin-right: 3px;
            border-radius: 6px;

            .single-schedule-time {
              display: flex;
              align-items: center;

              .session-icon {
                margin-right: 5px;

                svg {
                  display: block;
                  width: 20px;
                  height: 20px;
                }
              }

              span {
                font-weight: bold;
              }
            }

            .single-schedule-icons {
              display: flex;
              align-items: center;
              margin-right: 8px;

              div {
                &:last-child {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

.rescedule-container {
  width: 100%;
  position: absolute;
  top: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .reschedule-description {
    position: relative;
    width: 95%;
    text-align: center;
    background-color: #dcf4ff;
    padding: 6px 0;
    margin-bottom: 5px;
    border-radius: 6px;
    border: 1.5px solid rgb(187, 223, 67);

    img {
      width: 30px;
      position: absolute;
      right: 10px;
      top: 0px;
    }
  }

  .details {
    width: 100%;
    border-bottom: 1px solid rgb(187, 223, 67);
    border-top: 1px solid rgb(187, 223, 67);
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .inputs-wrapper {
    width: 60%;
    margin-top: 12px;
  }
}

.reschedule-buttons {
  position: fixed;
  bottom: 52px;
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    width: 48%;
    padding: 10px 0;
    border-radius: 5px;
    border: none;
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    cursor: pointer;
  }
  .rescheduleBtn {
    margin-right: 8px;
    background-color: rgb(187, 223, 67);
    width: 50%;
  }
  .cancelBtn {
    margin-right: 4px;
    background-color: #d9d9d9;
    color: black;
    width: 45%;
  }
}

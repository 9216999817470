.phoneNumber {
  display: flex;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  color: inherit;

  .dial-icon {
    margin-right: 5px;
  }

  img {
    margin-right: 3px;
  }
}

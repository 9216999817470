.layout {
  width: 100%;
  height: 100svh;
  position: relative;
}

.header-layout {
  width: 100%;
}

.left.active {
  border-right: 1px solid #8f8f8f;
  border-radius: 5px;
  width: 80%;
  height: 100svh;
  background-color: #eef2f5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.right.active {
  width: 20%;
  height: 100svh;
  position: fixed;
  right: 0;
  z-index: 10;
}

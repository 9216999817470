.footer-container {
  position: fixed;
  bottom: 0%;
  width: 100%;
  background-color: #fff;
  z-index: 1;
}

.nav-container {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.links-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  padding: 8px 0;

  .sessions.active {
    color: rgb(187, 223, 67);
  }
  .history.active {
    color: rgb(187, 223, 67);
  }

  .nav-link {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;

    .icon {
      margin-bottom: 4px;
    }

    &.disabled {
      color: black;
    }
  }
}

.add-schedule {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2px;
}

.add-schedule button {
  width: 80%;
  padding: 10px 0;
  border-radius: 5px;
  border: none;
  background-color: rgb(187, 223, 67);
  color: #fff;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
}

.skedy-rights {
  width: 100%;
  text-align: center;
  padding: 15px;
  background-color: #eef2f5;
  text-transform: capitalize;
}

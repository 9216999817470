.details-container {
  width: 100%;
  position: absolute;
  top: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 110px;

  .details-description {
    position: relative;
    width: 90%;
    text-align: center;
    background-color: #dcf4ff;
    padding: 6px;
    margin-bottom: 5px;
    border-radius: 6px;
    border: 1.5px solid rgb(187, 223, 67);

    span {
      display: block;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .deatils-menu {
      width: 30px;
      position: absolute;
      right: 5px;
      top: 4px;
    }
  }

  .financial-details {
    width: 100%;
    border-bottom: 1px solid rgb(187, 223, 67);
    border-top: 1px solid rgb(187, 223, 67);
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    h4 {
      color: rgb(187, 223, 67);
      margin-bottom: 4px;
    }

    ul {
      width: 75%;
    }
  }

  .empty-data {
    text-align: center;
    padding: 40px;

    &.invite {
      display: none;
    }
  }

  .details-inputs-container {
    width: 80%;
    display: none;
  }

  .details-inputs-container.invite {
    display: block;
  }
}

.sub-details-container {
  width: 95%;

  font-size: 13px;

  .single-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dcf4ff;
    margin-top: 5px;
    padding: 6px;
    border-radius: 8px;

    .single-sub-name {
      h3 {
        text-transform: capitalize;
      }
    }

    .single-sub-icons {
      position: relative;

      display: flex;
      align-items: center;

      .single-sub-status {
        padding-right: 5px;
        span {
          color: #fda624;
        }
      }

      .due-payment {
        margin-right: 5px;
        img {
          width: 25px;
        }
      }

      .group-payer {
        position: relative;
        margin-right: 5px;
        cursor: pointer;
        #excl-mark {
          position: absolute;
          right: 20px;
          width: 10px;
        }
        img {
          width: 2 5px;
        }
      }

      img {
        padding-top: 5px;
      }
    }
  }
}

.sub-details-container.invite {
  display: none;
}

.details-invite-buttons {
  position: fixed;
  bottom: 52px;

  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    width: 48%;
    padding: 10px 0;
    border-radius: 5px;
    border: none;
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    cursor: pointer;
  }
  #details-invite {
    margin-left: 4px;
    background-color: rgb(187, 223, 67);
  }
  #details-back {
    margin-right: 4px;
    background-color: #d9d9d9;
    color: black;
  }
}

.auth-input-container {
  position: relative;
  .input {
    width: 340px;
    padding: 10px 0;
    margin-top: 10px;
    border: 1px solid rgb(185, 181, 181);
    border-radius: 6px;
    background-color: rgb(241, 241, 217);
    text-indent: 5px;
    font-size: 16px;
  }

  .input::placeholder {
    text-align: center;
    text-transform: capitalize;
  }

  div {
    width: 23px;
    position: absolute;
    right: 7px;
    top: 16.5px;
  }
}

.auth-input-container {
  .pass-password {
    display: block;
  }
  .pass-text {
    display: none;
  }
}

.auth-input-container.text {
  #hidden {
    display: none;
  }

  #eye {
    display: block;
  }
}

.auth-input-container.password {
  #hidden {
    display: block;
  }

  #eye {
    display: none;
  }
}

@media (max-width: 340px) {
  .auth-input-container {
    .input {
      width: 270px;
    }
  }
}

.provider-container {
  width: 100%;
  margin-top: 60px;
  padding-bottom: 100px;

  .schedule-list-container {
    &:not(:first-child) {
      margin-top: 0px;
    }

    .schedule-day {
      display: flex;
      justify-content: center;
      padding: 4px 0 4px 16px;
      font-weight: 400;
      color: black;
      background: none;

      > span {
        border-bottom: 1px solid rgb(187, 223, 67);
        border-top: 1px solid rgb(187, 223, 67);
        width: 90%;
        text-align: center;
      }
    }
    ul {
      display: flex;
      flex-direction: column;

      li {
        width: 100%;

        .schedule-container {
          margin-top: 2px;
          &:not(:first-child) {
            .schedule-icon {
              opacity: 0;
            }
          }

          display: flex;
          justify-content: space-around;
          align-items: center;

          .schedule-description {
            position: absolute;
            left: 30%;
            width: 215px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            text-align: center;

            &::first-letter {
              text-transform: uppercase;
            }
          }
          .schedule-icon-container {
            margin: 0 2px;

            .schedule-icon {
              position: relative;
              .schedule-group-single-icons {
                width: 40px;
                height: 40px;
              }
              .schedule-line {
                position: absolute;
                top: 10px;
                right: 50%;

                height: 70px;
                width: 2px;
                background-color: black;
                z-index: -1;
              }
            }
          }

          .schedule {
            width: 90%;
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #dcf4ff;
            padding: 0px 5px;
            margin-right: 3px;
            border-radius: 6px;
            .schedule-time {
              span {
                font-weight: bold;
              }
            }

            .schedule-icons {
              display: flex;
              align-items: center;
              margin-right: 8px;

              div {
                &:last-child {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

.empty-schedule {
  width: 100%;
  height: 70svh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.weekdays {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px;

  div {
    width: 10%;
    height: 40px;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    background-color: #ffffff;
    border: 1px solid rgb(187, 223, 67);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &:last-child {
      margin-right: 0;
    }

    span {
      &:first-child {
        font-size: 16px;
        text-transform: capitalize;
      }
      &:last-child {
        font-size: 12px;
      }
    }
  }
  .active {
    background-color: var(--positive_bg_color);
    color: #ffffff;
  }
}

.invitation-container-li {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #dcf4ff;
  list-style-type: none;
  border-radius: 6px;
  .invitation-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .invitation-from {
      font-size: 16px;
    }
    .invitation-name {
      width: 260px;

      display: flex;
      justify-content: center;

      align-items: center;
      text-transform: capitalize;

      margin: 5px 0;

      .activity-icon {
        width: 40px;
        margin-right: 5px;
        svg {
          width: 100%;
          height: auto;
        }
      }

      h2 {
        max-width: 210px;
        font-size: 16px;
      }
    }

    .provider-phone {
      margin: 5px 0;
    }
    .financial-details {
      width: 100%;
      ul {
        margin-left: 20px;
        li {
          margin-top: 10px;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }

    .invitation-buttons {
      width: 100%;

      display: flex;
      justify-content: center;

      margin-top: 20px;

      button {
        width: 100px;
        color: #fff;
        border: none;
        border-radius: 6px;
        padding: 15px 0;
        text-transform: capitalize;
        cursor: pointer;
      }
      #invitation-reject {
        button {
          margin-left: 20px;
          background-color: var(--negative_bg_color);
        }
      }

      #invitation-accept {
        button {
          background-color: var(--positive_bg_color);
        }
      }
    }
  }
}

.details-days {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px;

  div {
    width: 10%;
    height: 50px;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    background-color: #ffffff;
    border: 1px solid rgb(187, 223, 67);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &:last-child {
      margin-right: 0;
    }

    span {
      &:first-child {
        font-size: 18px;
        text-transform: capitalize;
      }
      &:last-child {
        font-size: 13px;
      }
    }
  }

  .active {
    background-color: var(--positive_bg_color);
    color: #ffffff;
  }
}

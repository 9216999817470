@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */

:root {
  --positive_bg_color: #b6c700;
  --negative_bg_color: #c11313;
}

* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Inter", sans-serif;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: black;
}

@media all and (display-mode: standalone) {
  body {
    background-color: #fff;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-body {
  width: 100%;
  height: 100%;

  &.center {
    width: auto;
    height: auto;
    position: relative;
    background: none;
    min-width: 250px;
    min-height: 150px;
  }
}

.tooltip {
  position: absolute;
  background: #333;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
  max-width: 250px;
  transition: opacity 0.3s ease;

  ul {
    padding-left: 16px;
    margin-bottom: 8px;
  }
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.triangle-right {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #333;
  top: 0;
  right: -8px;
}

.triangle-left {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #333;
  top: 0;
  left: -8px;
}

.triangle-top {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #333;
  top: -8px;
  left: 0px;
}

.triangle-bottom {
  position: absolute;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid #333;
  bottom: -8px;
  right: 0;
}

.tooltip-content {
  margin-bottom: 10px;
}

.tooltip-buttons {
  display: flex;
  justify-content: flex-end;
}

.tooltip-buttons button {
  background: rgb(187, 223, 67);
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 5px;
}

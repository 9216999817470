.itemCards-wrapper {
  display: flex;
  justify-content: space-around;
  overflow-x: auto;
  white-space: nowrap;
  background: #f0f0f0;
  padding: 10px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  width: 100%;
  z-index: 1;
  scrollbar-width: none;

  // @media (min-width: 768px) {
  //   &.center {
  //     justify-content: center;
  //   }
  // }
}

.itemCards-wrapper::-webkit-scrollbar {
  display: none;
}

.itemCard {
  height: 60px;
  width: 45px;
  border-radius: 4px;
  margin: 0 2px;
  padding: 0px 10px;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid rgb(187, 223, 67);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-weight: bold;

  .itemText {
    font-size: 20px;
  }

  .itemSubtext {
    font-size: 14px;
  }

  &:first-child {
    margin-left: 4px;
  }

  &:last-child {
    margin-right: 4px;
  }
}

.itemCard.active {
  font-weight: bold;
  background-color: rgb(187, 223, 67);
  color: #ffffff;
}

.itemCard.disabled {
  opacity: 0.4;
}

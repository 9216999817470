.main-container {
  position: relative;
  width: 100%;
  height: 90svh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.main-title {
  text-align: center;

  font-size: 2rem;
  color: var(--positive_bg_color);
}

.bouncing-ball {
  display: flex;
  justify-content: center;
}

.bouncing-ball img {
  width: 100px;
  border-radius: 50%;
}

.main-contant {
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.login-form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-form h3 {
  width: 340px;
  text-align: center;
  color: rgb(241, 68, 15);
}

.login-form h3::first-letter {
  text-transform: uppercase;
}

.tennis-court {
  width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.login-button {
  width: 340px;
  padding: 5px 0;
  border: 1px solid var(--positive_bg_color);
  border-radius: 0.5rem;
  color: #fff;
  background-color: var(--positive_bg_color);
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 10px;
  cursor: pointer;
}

.create {
  font-size: 0.9375rem;
  color: #8f8f8f;
  font-weight: 400;
  cursor: pointer;
}

.reset-header-text {
  // margin-bottom: 10px;
}

.reset-text {
  width: 80%;
  color: #8f8f8f;
  text-align: center;
  margin-top: 10px;
}

.reset-password {
  @extend .create;
}

.reset-button {
  @extend .login-button;
}

.back-to-login {
  color: #8f8f8f;
  font-weight: 600;
  cursor: pointer;
}

.reset-password-form {
  width: 340px;
  text-align: center;
  color: rgb(241, 68, 15);
}

@media (max-width: 340px) {
  .login-button {
    width: 80%;
  }
  .tennis-court {
    width: 270px;
  }
  .login-form h3 {
    width: 270px;
  }
}

.details-container {
  .date {
    width: 100%;
    border-bottom: 1px solid rgb(187, 223, 67);
    border-top: 1px solid rgb(187, 223, 67);
    text-align: center;
    margin-bottom: 16px;
    padding: 12px 0;
  }

  .empty-data {
    text-align: center;
    padding: 40px;

    &.invite {
      display: none;
    }
  }
}

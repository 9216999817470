.log-out-container.active {
  display: flex;
}

.log-out-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(80, 80, 76, 0.8);
  z-index: 3;
  display: none;
}

.log-out {
  width: 270px;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 10px;

  #question-icon {
    margin-bottom: 20px;
    img {
      width: 30px;
    }
  }

  h2 {
    font-weight: 500;
  }
}

.log-out-confirmation {
  width: 200px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  text-transform: capitalize;

  div {
    width: 50px;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
  }
  #yes {
    margin-right: 10px;
    border: 1px solid #303f9f;
    span {
      color: #303f9f;
    }
  }
  #no {
    margin-left: 10px;
    background-color: #fce444;
  }
}

.log-out-confirmation h3 {
  cursor: pointer;
}

.sandwich-container {
  width: 80%;
  background: #eef2f5;
  position: fixed;
  left: 0px;
  top: 0px;
  display: none;
  z-index: 10;
}

.sandwich-content {
  height: 250px;
}

.sandwich-content ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sandwich-content li {
  padding: 2px 0;
  padding-left: 10px;
  list-style-type: none;
  cursor: pointer;
}

.sandwich-content span {
  text-transform: capitalize;
}
.username {
  display: flex;
  align-items: center;
  padding: 10px;
  span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-weight: 500;
    text-transform: capitalize;
  }
}
.username img {
  width: 2.5rem;
}
.username span {
  margin-left: 5px;
}

.logout {
  padding: 5px;
  margin-left: 10px;
  background-color: gray;
  color: #fff;
  text-transform: capitalize;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.sandwich-container.active {
  display: block;
}

.lang-switcher {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.lang-switcher button {
  background: none;
  border: none;
  cursor: pointer;
}

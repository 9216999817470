.notfound {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eaff00;
  background-color: rgb(243, 243, 172);
  z-index: 3;
}

.notfound h1 {
  color: red;
  text-transform: capitalize;
  text-align: center;
}

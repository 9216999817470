.customer {
  width: 100%;
}

.may {
  width: 100%;
  background-color: #ede94a;
  display: flex;
  justify-content: center;
  padding: 15px 0;
}
.may h3 {
  text-transform: capitalize;
}

.single-customer {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
}

.lesson {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.lesson img {
  margin-right: 5px;
}

.customer-content {
  width: 95%;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 2px 2px;
  background-color: #dcf4ff;
  border-radius: 3px;
}

.single-customer li {
  list-style-type: none;
}

.day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #d9d9d9;
  border-radius: 50%;
  font-weight: bold;
}

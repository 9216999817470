.menu-wrapper {
  position: relative;
  display: inline-block;
  height: 24px;
}

.menu-toggle {
  height: 24px;
  width: 24px;
  font-size: 24px;
  background: none;
  border: none;

  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
}

.menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  right: 0;
  width: 190px;

  &.menu-below {
    top: calc(100% + 5px);
  }

  &.menu-above {
    bottom: calc(100% + 5px);
  }
}

.menu-item {
  padding: 8px;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: #fff;
    }
  }
}

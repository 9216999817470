.provider-history-attenders-container {
  margin-top: 70px;

  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .event-date {
    display: flex;
    color: var(--positive_bg_color);

    h2 {
      &:first-child {
        margin-right: 7px;
      }
    }
  }

  .description {
    margin: 2px 0;

    .description-icon {
      margin-right: 5px;
    }

    display: flex;
  }

  .section-devider {
    width: 95%;
    height: 1.5px;
    background-color: var(--positive_bg_color);
  }

  .attenders-card-container {
    width: 100%;

    position: fixed;
    top: 135px;
    bottom: 90px;
    overflow: auto;

    padding-bottom: 5px;

    ul {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      li {
        width: 95%;

        list-style-type: none;
        margin-top: 5px;

        .card-item {
          width: 100%;
          display: flex;
          align-items: center;

          padding: 6px 0;
          background-color: #dcf4ff;
          border-radius: 6px;

          .card-item-content {
            width: 30%;

            display: flex;
            align-items: center;

            padding: 0 10px;

            .card-time {
              margin: 0 10px;
              font-weight: 700;
            }
            .card-status {
              height: 21px;
            }
          }

          .card-name {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
  .provider-registraton-back {
    position: fixed;
    bottom: 40px;
    margin: 20px;
    font-weight: 700;
    color: #8f8f8f;
    cursor: pointer;
  }
}

@media (max-width: 400px) {
  .provider-history-attenders-container {
    .event-date {
      display: block;
      text-align: center;
    }

    .attenders-card-container {
      top: 165px;
    }
  }
}

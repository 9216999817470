.consumer-bycustomer-details {
  margin-top: 70px;

  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .event-date {
    color: var(--positive_bg_color);
  }

  .description {
    margin: 2px 0;
    .description-icon {
      margin-right: 5px;
    }

    display: flex;
  }

  .section-devider {
    width: 95%;
    height: 1.5px;
    background-color: var(--positive_bg_color);
  }

  .details-card-container {
    width: 100%;

    position: fixed;
    top: 220px;
    bottom: 90px;
    overflow: auto;

    padding-bottom: 5px;

    ul {
      li {
        width: 100%;
        display: flex;
        justify-content: center;

        list-style-type: none;
        margin-top: 5px;

        .details-card {
          width: 95%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .card-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 6px;
            background-color: #dcf4ff;
            border-radius: 6px;

            .card-name {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;

              text-transform: capitalize;
            }

            .card-item-content {
              width: 190px;
              display: flex;
              align-items: center;
              flex-shrink: 0;
              font-weight: 700;

              .card-status {
                height: 21px;
              }

              .card-weekday {
                width: 45px;
              }

              .card-time {
                margin: 0 10px;
              }
            }
          }
        }
      }
    }
  }
  .back-btn {
    position: fixed;
    bottom: 40px;
    margin: 20px;
    font-weight: 700;
    color: #8f8f8f;
    cursor: pointer;
  }
}

.header {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 2;
}

.header-container {
  position: relative;
  width: 95%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .title h1 {
  color: var(--positive_bg_color);
  text-transform: capitalize;
  cursor: default;
}

.sandwich {
  position: absolute;
  left: 0px;
  padding: 2px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 300px;
}

.modal-icon {
  font-size: 24px;
  color: green;

  span {
    border: 2px solid;
    border-radius: 50%;
    padding: 3px 6px;
  }

  &.error {
    color: red;

    span {
      padding: 2px 0px;
    }
  }
}

.modal-message {
  margin: 20px 0;
}

.modal-close {
  background: none;
  border: 2px solid #007bff;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #007bff;
}

.modal-close:hover {
  background: #007bff;
  color: white;
}
.training-history-container {
  width: 100%;
  // height: 100svh;
  margin-top: 65px;
  padding-bottom: 110px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .filters-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 140px;
    z-index: 5;
    background: #ffffff;
    padding-bottom: 8px;
  }

  .sort-history {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    div {
      text-align: center;
      text-transform: capitalize;
      cursor: pointer;
      &:first-child {
        margin-right: 30px;
      }
    }

    .sort-byschedule-nav.byschedule {
      color: var(--positive_bg_color);
      border-bottom: 1px solid var(--positive_bg_color);
    }
    .sort-bycustomer-nav.bycustomer {
      color: var(--positive_bg_color);
      border-bottom: 1px solid var(--positive_bg_color);
    }
  }

  .history-search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    input {
      width: 94%;
      height: 44px;
      padding: 0 0 0 16px;

      border-radius: 4px;
      border: 2px solid rgb(196, 196, 196);

      &::placeholder {
        color: rgb(196, 196, 196);
      }
    }

    button {
      position: absolute;
      right: 3%;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
}

.training-history-cards-container {
  width: 98%;
  margin-top: 10px;
  .training-history-ul {
    .training-history-li {
      display: flex;
      justify-content: center;

      background-color: #dcf4ff;
      padding: 10px;
      margin-bottom: 5px;
      border-radius: 4px;

      list-style-type: none;

      .history-single-card {
        width: 100%;

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.history-description-container-byschedule {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .history-members {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 20px;
    width: 50px;

    background-color: #fff;
    border-radius: 5px;
    border: 1.5px solid var(--positive_bg_color);
    span {
      font-weight: 600;
    }
  }
  .history-description {
    display: flex;
    align-items: center;

    .history-description-text {
      width: 95%;
      font-weight: bold;
      span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .history-icon {
      display: flex;
      justify-content: center;
      margin-right: 3px;
    }
  }
}

.history-description-container-bycustomer {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  .history-account-info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .account-name {
      span {
        text-transform: capitalize;
        font-weight: 600;
      }
    }
  }
  .history-description-container {
    display: flex;
    align-items: center;

    margin: 10px 0;
    .members-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 25px;
      height: 25px;

      margin-right: 5px;
      border-radius: 50%;
      background-color: #d9d9d9;
      img {
        width: 17px;
      }
    }
    .description-text {
      span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.history-details-container {
  width: 98%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .session-logs-byschedule {
    .passed-sessions {
      display: flex;

      margin-bottom: 2px;

      .passed-sessions-icon {
        margin-right: 2px;
      }
    }
  }
  .session-logs-bycustomer {
    .passed-sessions {
      display: flex;

      margin-bottom: 6px;

      .passed-sessions-icon {
        margin-right: 3px;
      }
    }
  }

  .history-details-container-divider {
    width: 1px;
    height: 60px;
    margin: 0 3px;
    background-color: var(--positive_bg_color);
  }

  .show-details {
    width: 40%;
    button {
      width: 100%;
      font-weight: 600;
      background-color: #fff;
      padding: 6px 0;
      border-radius: 4px;
      border: 1.5px solid var(--positive_bg_color);
      cursor: pointer;
    }
  }
}

.no-historical-records {
  margin-top: 50%;
  text-align: center;
}

@media (max-width: 355px) {
  .sort-history {
    div {
      width: 110px;
    }
  }
}

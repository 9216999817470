.invite-container {
  padding-top: 75px;
  padding-bottom: 90px;

  .create-invitatiion-header {
    text-align: center;
    margin-bottom: 30px;
  }

  .form-container {
    display: flex;
    justify-content: center;
    form {
      width: 90%;

      .form-section-header {
        position: absolute;
        left: 0;
        width: 100%;
        margin-bottom: 200px;
        text-align: center;
        color: var(--positive_bg_color);
        border-bottom: 1px solid var(--positive_bg_color);
        z-index: -1;
      }

      .form-section-divider {
        margin-top: 50px;
      }

      .days-selection {
        margin: 15px 0;
        .details-days {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin: 8px 0 16px;
          div {
            width: calc(100% / 7);
            height: 50px;
            border-radius: 4px;
            margin-right: 2px;
            padding: 4px;
            background-color: #d9d9d9;
            border: 1px solid rgb(187, 223, 67);
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            &:last-child {
              margin-right: 0;
            }

            span {
              &:first-child {
                font-size: 20px;
                text-transform: capitalize;
              }
              &:last-child {
                font-size: 13px;
              }
            }
          }
          .active {
            background-color: var(--positive_bg_color);
            color: #ffffff;
          }
        }
        .time-picker-modal {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          padding: 1rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          z-index: 1000;

          @media (max-width: 768px) {
            opacity: 0;
          }
        }

        .dateSelectionError {
          color: red;
          font-size: 12px;
        }

        .dateSelectionLabel {
          color: rgb(143, 142, 142);
          font-size: 12px;
        }
      }
    }
  }

  .invite-button-container {
    position: fixed;
    bottom: 52px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    #add-schedule {
      width: 48%;
      padding: 10px 0;
      border-radius: 5px;
      border: none;
      background-color: var(--positive_bg_color);
      color: #fff;
      text-transform: capitalize;
      font-weight: bold;
      cursor: pointer;
    }

    #cancel {
      width: 48%;
      padding: 10px 0;
      border-radius: 5px;
      border: none;
      background-color: var(--negative_bg_color);
      color: #fff;
      text-transform: capitalize;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.input-date {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 16px;

  input {
    width: 100%;
    border: none;
    border-bottom: 2px solid rgb(188, 185, 185);
    background-color: #f5f5f5;
    border-radius: 6px 6px 0 0;
    margin-bottom: 3px;
    padding: 10px 0;

    -webkit-appearance: none;
    appearance: none;
  }
  input[type="date"]::-webkit-inner-spin-button,
  label {
    color: rgb(143, 142, 142);
    font-size: 11px;
    text-transform: capitalize;
  }
}
